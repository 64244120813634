import {Carousel} from "react-responsive-carousel";
import backgroundImage from "../assets/images/back_forest1.jpg";
import backgroundImage2 from "../assets/images/back_forest2.jpg";
import backgroundImage3 from "../assets/images/back_forest3.jpg";
import style from "./MainCarousel.module.css";

export function MainCarousel() {
    return <div style={{marginBottom: "5em"}}>
        <Carousel autoPlay showThumbs={false} showStatus={false} preventMovementUntilSwipeScrollTolerance={true} and swipeScrollTolerance={50} infiniteLoop={true}>
            <div style={{height: "100%"}}>
                <div className={style.section}
                     style={{backgroundImage: `url(${backgroundImage})`}}>
                    <div className="section-container">
                        Psicoterapia de reparación para adultos sobrevivientes de abuso sexual en la infancia y adolescencia
                    </div>
                </div>
            </div>
            <div style={{height: "100%"}}>
                <div className={style.section}
                     style={{backgroundImage: `url(${backgroundImage2})`}}>
                    <div className="section-container">
                        Psicoterapia adultos
                    </div>
                </div>
            </div>
            <div style={{height: "100%"}}>
                <div className={style.section}
                     style={{backgroundImage: `url(${backgroundImage3})`}}>
                    <div className="section-container">
                      Psicoterapia familiar
                    </div>
                </div>
            </div>
        </Carousel>
    </div>;
}