import style from "./Experience.module.css"
import amandaImg from "../assets/images/amanda.webp";
import {email} from "../assets/constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";


export function Experience() {
    return <div className="section-container container-padding">
        <div class={style.section}>
            <h1>Sobre mi</h1>
            <img src={amandaImg} className={style.image} style={{maxWidth: "500px", width: "100%"}} alt="Amanda Morales"/>
            <div className={style.text}>
            <div>Postítulo de especialización en Psicoterapia Estratégica Breve</div>
            <div>Diplomado en intervenciones clínicas en abuso sexual en infancia y adolescencia</div>
            <div>Diplomado en Criminología mención Ley de Responsabilidad penal adolescente</div>
                    <div>Formación en Enfoque Sistémico</div>
                        <div>Formación Jurídico - forense</div>
                            <div>Experiencia en Programa de Intervención Especializada y programa de prevención focalizada, antigüo SENAME</div>
                                <div>Experiencia en área educacional, clínica, forense</div>
                                    <div>Trabajo en redes (Tribunales, programas de protección, salud, educación)</div>
            </div>
            <a href="https://www.psicologosonline.cl/centros/amanda-morales-mardones"
               target="_blank" className={style.capsule} style={{maxWidth: "400px", width: "100"}}>
                Más información en psicólogos online</a>
        </div>
    </div>;
}