import styles from './SocialButtons.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInstagram, faWhatsapp, faLinkedinIn, faFacebookF} from "@fortawesome/free-brands-svg-icons";
import {telephoneNumber} from "../assets/constants.js"

export function SocialButtons() {

    //telephoneNumber with only numbers
    const telephoneNumberOnlyNumbers = telephoneNumber.replace(/\D/g, '');
    const message = `Hola, quisiera agendar una hora`;
    //message as urlencode
    const messageUrlEncode = encodeURIComponent(message);

    return <div className={styles.section}>
        <div>
            <a className={`${styles.iconWhatsapp} ${styles.circleLink}`} alt="Whatsapp"
               href={`https://wa.me/${telephoneNumberOnlyNumbers}?text=${messageUrlEncode}`} target="_blank">
                <FontAwesomeIcon icon={faWhatsapp}/>
            </a>
        </div>
        <div>
            <a className={`${styles.iconFacebook} ${styles.circleLink}`} alt="Facebook"
               href="https://www.facebook.com/psicologaamandamorales" target="_blank">
                <FontAwesomeIcon icon={faFacebookF}/>
            </a>
        </div>
        <div>
            <a className={`${styles.iconLinkedin} ${styles.circleLink}`} alt="LinkedIn"
               href="https://www.linkedin.com/in/n%C3%A9lida-amanda-morales-mardones-48b74148" target="_blank">
                <FontAwesomeIcon icon={faLinkedinIn}/>
            </a>
        </div>
        <div>
            <a className={`${styles.iconInstagram} ${styles.circleLink}`} alt="Instagram"
               href="https://www.instagram.com/lapsicologabailarina" target="_blank">
                <FontAwesomeIcon icon={faInstagram}/>
            </a>
        </div>
    </div>;
}