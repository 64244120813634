import style from "./Horario.module.css"
import {telephoneNumber} from "../assets/constants";
function Hora({children, day}) {


    const telephoneNumberOnlyNumbers = telephoneNumber.replace(/\D/g, '');
    const message = `Hola, quisiera agendar una hora para el día ${day} a las ${children}`;
    //message as urlencode
    const messageUrlEncode = encodeURIComponent(message);
    return <a className={style.element} href={`https://wa.me/${telephoneNumberOnlyNumbers}?text=${messageUrlEncode}`} target="_blank">{children}</a>;
}

function DayTitle({children}) {
    return <div className={style.title}>{children}</div>;
}

export function Horario() {


    return <div className="section-container container-padding">
        <div className={style.section}>
            <h1>Horario online</h1>
            <div className={style.columnContainer}>
                <div className={style.column}>
                    <DayTitle>Lunes</DayTitle>
                    <Hora day={"Lunes"}>17:00</Hora>
                    <Hora day={"Lunes"}>18:00</Hora>
                    <Hora day={"Lunes"}>19:00</Hora>
                </div>
                <div className={style.column}>
                    <DayTitle>Miércoles</DayTitle>
                    <Hora day={"Miércoles"}>17:00</Hora>
                    <Hora day={"Miércoles"}>18:00</Hora>
                    <Hora day={"Miércoles"}>19:00</Hora>
                </div>
            </div>
        </div>
        <div className={style.section}>
            <h1>Horario presencial</h1>
            <div style={{marginTop: "-2em", marginBottom: "2em", fontStyle: "italic"}}>Con mascarilla</div>
            <div className={style.columnContainer}>
                <div className={style.column}>
                    <DayTitle>Viernes</DayTitle>
                    <Hora day={"Viernes"}>9:00</Hora>
                    <Hora day={"Viernes"}>10:00</Hora>
                    <Hora day={"Viernes"}>11:00</Hora>

                </div>
            </div>
        </div>
    </div>;
}