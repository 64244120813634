import style from "./InfoContact.module.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faPhoneAlt} from "@fortawesome/free-solid-svg-icons";
import {telephoneNumber, email} from "../assets/constants.js"

export function InfoContact() {
    return <div className="section-container container-padding">
        <div class={style.section}>
            <h1>Contacto</h1>
            <a className={style.capsule} href={`tel:${telephoneNumber}`}
            ><FontAwesomeIcon icon={faPhoneAlt}/>
                <div style={{width: "1em"}}/>
                {telephoneNumber}
            </a>
            <a href={`mailto:${email}`} className={style.capsule}><FontAwesomeIcon icon={faEnvelope}/>
                <div style={{width: "1em"}}/>
                psiamandamorales
                <wbr/>
                @
                <wbr/>
                gmail.com</a>
        </div>
    </div>;
}