import logo from "../assets/images/logo_119x119_contain.png";
import styles from "./Header.module.css";

export function Header() {
    return <header className="section-container">
        <div className={styles.section}>
            <div className={styles.headerLogo}>
                <img src={logo}/>
            </div>
            <div className={styles.headerTitle}>
                <div className={styles.title}>Ps. Amanda Morales</div>
                <div>Familiar & Adultos</div>
            </div>
        </div>
    </header>;
}