import style from "./Consulta.module.css"

export function Consulta() {
    return <div className="section-container container-padding">
        <div class={style.section}>
            <h1>Consulta</h1>
            <div className={style.capsule} style={{marginBottom: "2em"}}>
                <div style={{marginBottom: '-0.7em'}}>Valor único</div>
                <div style={{fontSize: '2em'}}>$40.000</div>
            </div>
            <h3 style={{marginBottom: '1.5em'}}>Consideraciones</h3>
            <div>
                <div style={{marginBottom: '1em'}}>Se paga al confirmar asistencia (2 días antes)</div>
                <div style={{marginBottom: '1em'}}>Se emite boleta electrónica</div>
                <div style={{marginBottom: '1em'}}>Si dispone de seguro médico, enviar formulario al confirmar su asistencia</div>
            </div>
        </div>
    </div>;
}