import './App.css';
import {Header} from "./component/Header";
import {SocialButtons} from "./component/SocialButtons";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {MainCarousel} from "./component/MainCarousel";
import {Horario} from "./component/Horario";
import {Consulta} from "./component/Consulta";
import {InfoContact} from "./component/InfoContact";
import {Experience} from "./component/Experience";
import logo from "./assets/images/logo_119x119_contain.png";

function App() {
    const currentYear = new Date().getFullYear();
    return (
        <div>
            <Header/>
            <MainCarousel/>
            <SocialButtons/>
            <Experience/>

            <Horario/>
            <Consulta/>
            <InfoContact/>
            <footer>
                <div className="section-container container-padding" style={{textAlign: "center", marginTop: "3em", marginBottom: "3em"}}>
                    <img src={logo}/>
                    <div className="footer-text">© {currentYear} - Psicóloga Amanda Morales</div>
                </div>
            </footer>
        </div>
    );
}

export default App;
